import { User } from '../models/User'
import { CHAT_STATUS, PLATFORMS } from '../constants/constants'
import * as themeConfig from '@/utils/themeConfig'

const generateChatLink = chat => {
  let urlChat = window.location.origin
  const hash = '/#/'
  if (window.location.href.includes(hash)) urlChat += hash
  else urlChat += '/'
  urlChat += `attention-requests?chat=${chat}`
  return urlChat
}

const connect = state => {
  const session = localStorage.getItem('session')
  const token = JSON.parse(session)?.token
  state.socket.client.emit('updateControls', {
    token,
    services: state.botsToSubscribe,
    queues: state.queuesToSubscribe
  })
  state.socket.client.emit('updateSenders', {
    token,
    senders: state.chatsToSubscribe
  })
}

const mutations = {
  SET_ENVIRONMENT(state, value) {
    state.config.environment = value
  },
  SET_BACKEND(state, value) {
    state.config.konecta = value
  },
  SET_WIDGET_URL(state, value) {
    state.config.konectaWidget = value
  },
  SET_WIDGET_STAGING(state, value) {
    state.config.konectaWidgetStaging = value
  },
  SET_CHAT(state, value) {
    state.config.chat = value
  },
  SET_VIDEOCALL(state, value) {
    state.config.videocall = value
  },
  SET_ANSWERS_LAZY_LOADING(state, value) {
    state.config.answersLazyLoading = value || false
  },

  SOCKET_CONNECT(state) {
    console.info('SOCKET_CONNECT')
    connect(state)
  },

  SOCKET_CHANGE_CHAT_STATUS(state, data) {
    // Triggered when the admin is on the `/attention-requests` page and a chatbot
    // triggers the "hands off" event on the chat, the adming will be notified
    // and a "New chat" notification will appear on the browser
    if (data.status === CHAT_STATUS.PENDING) {
      const title = state.lang.attentionRequests.newChat[state.languageSelected]
      let text = state.lang.attentionRequests.newChat[state.languageSelected]

      if (data.botName && data.platform) {
        const platform = PLATFORMS.find(p => p.value === data.platform)

        /** Check if platform exist before status change */
        if (platform?.name) {
          text = `${data.botName} • ${platform?.name}`
        }
      }

      const urlChat = generateChatLink(data.chat)
      const notification = new Notification(title, {
        body: text,
        icon: new URL('../assets/images/icons/kona.png', import.meta.url).href
      })

      notification.onclick = event => {
        event.preventDefault()
        window.open(urlChat, '_blank')
      }
    }
  },
  /**
   * Display a browser notification when the user recieves a websocket message
   */
  SOCKET_MESSAGE(state, data) {
    const title =
      state.lang.attentionRequests.newMessage[state.languageSelected]
    const text =
      typeof data.message.input === 'string'
        ? data.message.input
        : data.message.input[0]

    const urlChat = generateChatLink(data.chat)
    const notification = new Notification(title, {
      body: text,
      icon: new URL('../assets/images/icons/kona.png', import.meta.url).href
    })

    notification.onclick = event => {
      event.preventDefault()
      window.open(urlChat, '_blank')
    }
  },

  UPDATE_SESSION(state, session) {
    session.user = new User(
      session.user.name,
      session.user.email,
      '',
      session.roles,
      session.company,
      session.user._id
    )
    state.session = session
  },
  CLEAR_SESSION(state) {
    state.session = {}
  },

  SELECT_BOT(state, bot) {
    state.bot = bot
  },

  UPDATE_ACTIVE_CHAT(state, activeChatUser = null) {
    state.activeChatUser = activeChatUser
  },

  NEW_CHAT_USER_NAME(state, activeChatUser = null) {
    state.newChatUserName = activeChatUser
  },

  UPDATE_ACTIVE_MESSAGES(state, activeChatMessages) {
    state.activeChatMessages = activeChatMessages
  },

  UPDATE_CHAT_USER_STATUS(state, chatUserStatus) {
    state.chatUserStatus = chatUserStatus
  },

  UPDATE_BOT(state, bot) {
    state.bot = bot
  },

  UPDATE_ATTACHMENT_FILE(state, attachmentFile) {
    state.attachmentFile = attachmentFile
  },

  UPDATE_BOTS_LIST(state, bot) {
    let bots = state.bots
    bots.push(bot)
    state.bots = bots
  },

  UPDATE_FILTERS(state, filters) {
    state.filters = filters
  },

  MAXIMIZE_CHAT_CONTAINER(state) {
    state.maximizeChatContainer = !state.maximizeChatContainer
  },

  UPDATE_TEXT_TO_HIGHLIGHT(state, textToHighlight) {
    state.textToHighlight = textToHighlight
  },

  UPDATE_LANGUAGE(state, languageSelected) {
    state.languageSelected = languageSelected
  },

  UPDATE_PASSWORD_VALIDITY(state, passwordValidity) {
    state.passwordValidity = passwordValidity
  },

  UPDATE_SECURITY(state, security) {
    state.security = security
  },

  UPDATE_CHAT_TRANSITION_SETTINGS_SCOPE(state, chatTransitionSettingsScope) {
    state.chatTransitionSettingsScope = chatTransitionSettingsScope
  },

  UPDATE_CHAT_TRANSITION_SETTINGS(state, chatTransitionSettings) {
    state.chatTransitionSettings = chatTransitionSettings
  },

  UPDATE_CHAT_GENERAL_SETTINGS(state, chatGeneralSettings) {
    state.chatGeneralSettings = chatGeneralSettings
  },

  UPDATE_VIDEO_CALL(state, videoCall) {
    state.videoCall = videoCall
  },

  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  UPDATE_SIDEBAR_WIDTH(state, width) {
    state.sidebarWidth = width
  },
  UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
    state.sidebarItemsMin = val
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val
  },
  TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
    state.isSidebarActive = value
  },
  TOGGLE_SIDEBAR(state) {
    state.isSidebarActive = !state.isSidebarActive
  },
  UPDATE_THEME(state, val) {
    state.theme = val
  },
  UPDATE_BOTS_TO_SUBSCRIBE(state, val) {
    state.botsToSubscribe = val
  },
  UPDATE_QUEUES_TO_SUBSCRIBE(state, val) {
    state.queuesToSubscribe = val
  },
  UPDATE_CHATS_TO_SUBSCRIBE(state, val) {
    state.chatsToSubscribe = val
  },
  UPDATE_SOCKET(state, val) {
    state.socket = val
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val
  },
  UPDATE_LOGIN_METHOD(state, val) {
    state.loginMethod = val
  },
  SET_COMPANY_LOGO(state, companyLogo) {
    state.companyLogo = companyLogo
  },

  UPDATE_COMPANY_AUTH_KEY(state, companyAuthKey) {
    state.companyAuthKey = companyAuthKey
  },

  UPDATE_COMPANY_ID(state, companyId) {
    state.companyId = companyId
  },

  UPDATE_THEME_COLORS(state, themeColors) {
    // replace Konecta by Cyberbank Konecta branding color
    if (themeColors.primary === 'rgb(255, 129, 0)') {
      themeColors.primary = '#0096B6'
    }
    state.themeColors = {
      ...themeConfig.themeColors,
      ...themeColors,
    }
  },

  UPDATE_NEW_BOT_LANGUAGE(state, newBotLanguage) {
    state.newBotLanguage = newBotLanguage
  },

  SET_SUGGESTED_CHAT_MESSAGE(state, suggestedChatMessage) {
    state.suggestedChatMessage = suggestedChatMessage
  },

  UPDATE_SENT_SUGGESTION(state, sentSuggestion) {
    state.sentSuggestion = sentSuggestion
  },

  SET_CHANGE_PASSWORD(state, status) {
    state.changePassword = status
  },

  SET_COMPANY_CYBERBANK(state, useCyberbank) {
    state.useCyberbank = useCyberbank
  },

  SET_COMPANY_STAFF_MANAGEMENT(state, useStaffManagement) {
    state.useStaffManagement = useStaffManagement
  }
}

export default mutations
