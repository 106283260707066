<template>
  <router-view v-if="loadedTheme" />
</template>

<script>
import update from './mixins/update'
import { mapActions, mapMutations } from 'vuex'

export default {
  mixins: [update],
  data() {
    return {
      loadedTheme: false
    }
  },
  async mounted() {
    try {
      await this.initConfig();
      this.setStateSettings();

      // if user logged in
      if (localStorage.session) {
        try { 
          await this.getCompanySettings()
        } catch (_) {
          // the okta-backend login it is made after that call
        }
      }
      
      this.$vs.theme(this.$store.state.themeColors)
      document.body.classList.add('theme-semi-dark')
      this.restartSession()
      this.loadedTheme = true
  
      await this.$socket.init(this.$store)
      this.updateSocket(this.$socket)
    } catch (error) {
      console.error(error)
    }
  },
  beforeUnmount() {
    this.$socket.close()
  },
  methods: {
    ...mapActions([
      'updateAndSaveSession',
      'getCompanySettings',
      'updateSocket'
    ]),
    ...mapMutations(['CLEAR_SESSION']),
    async initConfig() {
      const res = await fetch(process.env.VUE_APP_CONFIG_FILE, { method: "get"});
      const config = await res.json()
      window.config = config;
    },
    setStateSettings() {
      this.$store.commit('SET_ENVIRONMENT', window.config.environment)
      this.$store.commit('SET_BACKEND', window.config.backend)
      this.$store.commit('SET_WIDGET_URL', window.config.widgetUrl)
      this.$store.commit('SET_WIDGET_STAGING', window.config.widgetStaging || 'true')
      this.$store.commit('SET_CHAT', window.config.chat)
      this.$store.commit('SET_VIDEOCALL', window.config.videocall)
      this.$store.commit('SET_ANSWERS_LAZY_LOADING', `${window.config.answersLazyLoading}`.trim() === 'true')
    },
    restartSession() {
      try {
        const session = JSON.parse(localStorage.session)
        this.updateAndSaveSession(session)
      } catch (_) {
        this.CLEAR_SESSION();
      }
    },
  },
}
</script>

<style lang="scss">
@use '../src/assets/scss/responsive.scss';
</style>
