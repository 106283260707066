import Helper from '../Helper'
import BotMakerService from '../../services/botMaker.service'
import variableService from '../../services/variable.service.js'
import scheduleService from '../../services/schedule.service.js'
import TagService from '../../services/tag.service.js'
import BotMarketplaceService from '../../services/botMarketplace.service'

import { generateBot } from '../../models/BotGenerator'

const actions = {
  async RESET_ENABLED_APPS({ state }) {
    try {
      state.enabledApps = []
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
  async GET_ENABLED_APPS({ state }, { enabledApps, serviceId, versionId }) {
    try {
      let aux = enabledApps
      if (!aux) {
        const response = await BotMarketplaceService.getInstalledAppsForResponse({
          service: serviceId,
          version: versionId,
          enabled: true
        })
        aux = response.data.data
      }
      for (const element of aux) {
        if (element?.appField?.properties) {
          element.otherFields = []
          const promises = []
          const operations = []
          for (let property of element.appField.properties) {
            if (!Object.hasOwn(property, 'isApi') || !property.isApi) continue
            operations.push(property.access)
            promises.push(new Promise(resolve => {
              BotMarketplaceService.getDataFromApp({
                appId: element._id,
                operation: property.access
              })
              .then(resolve)
              .catch((error) => {
                console.error(error)
                resolve([])
              })
            }))
          }
          const results = await Promise.all(promises)
          results.forEach((result, index) => {
            element.otherFields.push({
              operation: operations[index],
              data: result.data
            })
          })
        }
      }
      state.enabledApps = aux
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
  async GET_TAGS({ state }, versionId) {
    try {
      const response = await TagService.getTags(0, 500, versionId)
      state.tags = response.data
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
  async GET_SCHEDULES({ state }, versionId) {
    try {
      const response = await scheduleService.getSchedules(versionId)
      state.schedules = response.data
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
  async GET_VARIABLES({ state }, versionId) {
    try {
      const response = await variableService.getVariables(versionId)
      state.variables = response.data
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async GET_API_CALLS({ state }, versionId) {
    return BotMakerService.getApiCalls(versionId)
      .then(response => {
        state.apiCalls = response.data
      })
      .catch(error => error)
  },
  async GET_ACTIVE_VERSION({ commit }, [serviceId, queryVersion]) {
    /** Check if already passed version by query */
    if (queryVersion) return
    try {
      const response = await BotMakerService.getActiveVersion(serviceId)
      commit('SET_VERSION', {
        versionId: response.data[0]._id,
        versionFork: response.data[0].fork,
        forkTemplate: response.data[0].forkTemplate
      })
    } catch (e) {
      return e
    }
  },
  async GET_INTENTIONS({ state, commit }) {
    const activeVersion = state.bot.activeVersion
    try {
      const response = await BotMakerService.getIntentionsPerVersion(
        activeVersion
      )
      let intentions = response.data
      Helper.setIntentsScalesSecondLevel(state.bot.rawIntents, intentions)
      commit('SET_INTENTIONS', intentions)
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  GET_BOT({ state, commit }, botId) {
    return BotMakerService.getBot(botId)
      .then(response => {
        // Load version to void fetching later for the same data
        response.data.activeVersion = response.data.activeVersion
        || response.data.service.activeVersions[0].version
        
        const generatedBot = generateBot(response.data)
        state.bot = generatedBot
        commit('SET_BOT_PLAIN', response.data)
        commit('SET_CURRENT_VERSION', response.data?.service?.activeVersions[0]?.version)
      })
      .catch(error => error)
  },

  async GET_BOT_VERSIONS({ commit, state }, [serviceId, queryVersion]) {
    try {
      const response = await BotMakerService.getBotVersions(serviceId)
      commit('SET_BOT_VERSIONS', response.data)
      if (queryVersion) {
        const version = state.botVersions.find(el => el._id === queryVersion)
        if (version !== undefined) {
          // not working yet due to inconsistencies with other parts of the code
          commit('SET_VERSION', {
            versionId: version._id,
            versionFork: version.fork,
            forkTemplate: version.forkTemplate
          })
        }
      }
    } catch (error) {
      return await Promise.reject(error)
    }
  },
}

export default actions
